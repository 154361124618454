.size_box_upload_img500 {
    width: 400px !important;
    height: 300px !important;
    margin-bottom: 35px !important;
 
}
.img_box_product {
    width: 100% !important;
    height: 300px !important;
 
}
.circle_round_index {
    width: 25px !important;
    height: 25px !important;
    border-style: solid;
    border-color: #343a40;
    background-color: #343a40;
    padding-bottom: 18px;
    border-radius: 15px !important;
    margin-bottom: 5px !important;
}

.img-card-list {
    height: auto !important;
    width: 100px !important;

}

.wizard>.actions .disabled a,
.wizard>.actions .disabled a:active,
.wizard>.actions .disabled a:hover {
    background-color: #000 !important;
    color: #fff;
}

.wizard>.actions .disabled a,
.wizard>.actions .disabled a:active,
.wizard>.actions .disabled a:hover {
    background-color: #000 !important;
    color: #fff;

}

.wizard>.actions a,
.wizard>.actions a:active,
.wizard>.actions a:hover {
    background-color: #000 !important;
    color: #fff;
}

.wizard>.actions a,
.wizard>.actions a:active,
.wizard>.actions a:hover {
    background-color: #000 !important;
    color: #fff;
}



