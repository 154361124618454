.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__drawer-right___h_uSC-sass {
    top: 0 !important;
    right: 0 !important;
    min-width: 300px !important;
    max-width: 600px !important;
    width: auto !important;
    height: 100% !important;
}

.form-check-input:checked {
    background-color: #343a40 !important;
    border-color: #343a40 !important;
}
.nav {
    --bs-nav-link-color: #343a40 !important;
    --bs-nav-link-hover-color: #343a40 !important;
    --bs-nav-link-disabled-color: #343a40  !important;
 
}
.nav-tabs-custom .nav-item .nav-link.active {
    color: #343a40 !important;
}