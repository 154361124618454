.weekDays-selector input {
    display: none !important;
}

.weekDays-selector input[type=checkbox]+label {
    display: inline-block;
    border-radius: 6px;
    background: #dddddd;
    height: 40px;
    width: 40px;
    padding: 2px;
    margin-right: 3px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
}

.weekDays-selector input[type=checkbox]:checked+label {
    background: #343a40;
    color: #ffffff;
}